import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatableserver";
// import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
  name: "CreateInstPage",
  data() {
    let ss = new MainService();
    return {
      msg: "CreateInstPage",
      ss: ss,
      ajax: {
        url: ss.indexCreateInst(),
        headers: ss.getToken(),
      },
      columns: [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          visible: false,
        },
        {
          data: "DT_RowIndex",
          name: "DT_RowIndex",
          title: "N",
          orderable: false,
          searchable: false,
        },
        { data: "paterno", name: "paterno", title: "Paterno" },
        { data: "materno", name: "materno", title: "Materno" },
        { data: "nombre", name: "nombre", title: "Nombre" },
        { data: "ci", name: "ci", title: "CI" },

        {
          data: "action",
          orderable: false,
          title: "Acciones",
          searchable: false,
        },
      ],
      createinsts: [],
      createinst: {
        nota_d: "", // Inicializar nota_d aquí
        pdf: "", // Inicializar pdf aquí
        observ: "",
        Idioma: "",
        Instituto: "",
        Carera: "",
      },
      isLoading: false,
      errorBag: {},
      gestion: null,
      carreras: "",
      unidadAcademica: "",
      instituciones: "",
      idiomas: "",
      filterci: "",
    };
  },
  methods: {
    filterCreateInst() {
      this.errorBag = {};
      this.$refs["view-filter"].show();
    },
    filter() {
      this.isLoading = true;
      this.ss
        .filtradoCreateInst(this.filterci)
        .then((result) => {
          let response = result.data;
          this.createinst = response.data;
          this.gestion = response.gestion;
          this.instituciones = response.instituciones;
          this.idiomas = response.idiomas;
          this.carreras = response.carreras;
          this.unidadAcademica = response.unidadAcademica;
          this.$refs["view-createinst"].show();
          this.$refs["view-filter"].hide();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    showCreateInst(id) {
      this.isLoading = true;
      this.ss
        .showCreateInst(id)
        .then((result) => {
          let response = result.data;
          this.createinst = response.data;
          this.gestion = response.gestion;
          this.instituciones = response.instituciones;
          this.idiomas = response.idiomas;
          this.carreras = response.carreras;
          this.unidadAcademica = response.unidadAcademica;
          //console.log(response);
          this.$refs["view-createinst"].show();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    editCreateInst() {
      this.$refs["frm-createinst"].show();
      this.$refs["view-createinst"].hide();
    },
    cancelCreateInst() {
      if (this.createinst.id) {
        this.$refs["view-createinst"].show();
      }
      this.$refs["frm-createinst"].hide();
    },
    saveCreateInst() {
      // Obtener el archivo seleccionado
      let file = document.querySelector('input[type="file"]').files[0];

      // Crear un FormData para enviar el archivo al backend
      let formData = new FormData();
      formData.append("File", file);

      // Enviar el archivo al backend utilizando la función uploadFile
      this.ss
        .uploadFile(formData)
        .then((response) => {
          //console.log(response.data);
          this.createinst.pdf = response.data.data;
          //console.log(this.createinst);
          // Guardar la instancia
          this.ss
            .storeCreateInst(this.createinst)
            .then((result) => {
              //console.log(result);
              let response = result.data;
              this.$bvToast.toast(response.msg, {
                title: "Correcto",
                variant: "success",
                autoHideDelay: 5000,
              });
              this.$refs["frm-createinst"].hide();
              this.$refs["datatable-createinst"].reload();
            })
            .catch((error) => {
              this.errorBag = error.response.data.errors;
              this.$bvToast.toast(
                "Problema al Guardar el Registro, favor verificar los Datos",
                {
                  title: "Error",
                  variant: "danger",
                  autoHideDelay: 5000,
                }
              );
            });
        })
        .catch((error) => {
          console.error("Error al subir el archivo:", error);
          // Aquí puedes manejar los errores, por ejemplo, mostrar un mensaje de error al usuario
        });
    },

    deleteCreateInst() {
      this.$swal({
        title: "¿Estás seguro que deseas eliminar?",
        text: "Esta acción es irreversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.ss
            .destroyCreateInst(this.createinst)
            .then((result) => {
              let response = result.data;
              //console.log(response);
              this.$swal({
                title: "¡Eliminado!",
                text: "El archivo ha sido eliminado.",
                icon: "success",
              });
              this.$refs["view-createinst"].hide();
              this.$refs["datatable-createinst"].reload();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    createCreateInst() {
      this.errorBag = {};
      this.createinst.gestion = document.querySelector(
        'input[name="gestion"]'
      ).value;
      this.createinst.motivo = document.querySelector(
        'input[name="motivo"]'
      ).value;
      this.createinst.nota_d = document.querySelector(
        'input[name="nota_d"]'
      ).value;

      //console.log(this.createinst);
      this.ss
        .createcertCreateInst(this.createinst)
        .then((response) => {
          //console.log("URL del PDF:", response.data.pdf_url);
          window.open(response.data.pdf_url);
          this.$refs["frm-createinst"].show();
          this.$refs["view-createinst"].hide();
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast(
            "Problema al Crear el Certificado, favor verificar los Datos",
            {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            }
          );
        });
    },
    draw() {
      window.$(".btn-datatable-CreateInst").on("click", (evt) => {
        const data = window.$(evt.target)[0].id;
        this.showCreateInst(data);
      });
    },
  },
  components: {
    dataTable,
    // Loader
  },
  mounted() {
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (!persona) {
      this.$router.push("/Login");
    }
  },
};
